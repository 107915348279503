import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import {
  BuildConfig,
  CheckoutRequest,
  CheckoutResponse,
  ValidateDirectDebitDetailsRequest,
  ValidateDirectDebitDetailsResponse,
} from '@common/util-models';
import { retryBackoff } from 'backoff-rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { addNumberSubsequentPaymentsToCheckoutResponse } from '../utils/checkout-mapper/checkout-mapper.utils';

@Injectable()
export class CheckoutApiService {
  private config: BuildConfig;
  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService
  ) {
    this.config = buildConfigService.config;
  }

  validateDirectDebitDetails(
    validateDirectDebitRequest: ValidateDirectDebitDetailsRequest
  ): Observable<ValidateDirectDebitDetailsResponse> {
    const validateDirectDebitUrl = `${this.config.common}/directdebit/validate`;
    return this.httpClient.post<ValidateDirectDebitDetailsResponse>(
      validateDirectDebitUrl,
      {
        ...validateDirectDebitRequest,
        request_source: this.buildConfigService.config.catalogueStore,
      },
      {
        headers: {
          ...this.getHeaders(),
        },
      }
    );
  }

  checkout(checkoutRequest: CheckoutRequest): Observable<CheckoutResponse> {
    const checkoutUrl = `${this.config.basketApi}/checkout`;
    return this.httpClient
      .post<{ result: CheckoutResponse }>(
        checkoutUrl,
        {
          ...checkoutRequest,
        },
        {
          headers: {
            ...this.getHeaders(),
          },
        }
      )
      .pipe(
        retryBackoff({
          initialInterval: this.config.retryBackOffConfig.initialInterval,
          maxRetries: this.config.retryBackOffConfig.maxRetries,
          maxInterval: this.config.retryBackOffConfig.maxInterval,
        }),
        map((response: { result: CheckoutResponse }) => response.result),
        map((response: CheckoutResponse) =>
          addNumberSubsequentPaymentsToCheckoutResponse(response)
        )
      );
  }

  private getHeaders() {
    return {
      'wl-client': this.buildConfigService.config.wlClient,
      'request-source': this.buildConfigService.config.catalogueStore,
      'request-action': this.buildConfigService.config.action,
    };
  }
}

<div class="feature-hero">
  <ui-glow-shadow-container>
    <div class="container glow-shadow__container">
      <div class="flex">
        <div class="feature-hero__container">
          <img [src]="src" [alt]="altTag" />
        </div>
        <div class="md-ml-md md-pl-xl feature-hero__content">
          <h5 class="pt-md pb-sm">{{ featureTitle }}</h5>
          <ul class="pb-md">
            <li *ngFor="let feature of topFeatures" class="feature-hero__list">
              <dgx-components-icon
                [svgIcon]="iconName | iconName"
              ></dgx-components-icon>
              <span class="pl-sm"> {{ feature.title }}</span>
            </li>
          </ul>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </ui-glow-shadow-container>
</div>

<div class="icons__container">
  <div
    class="icons__container__icon-set flex justify--center text--center"
    *ngFor="let icon of iconSet"
  >
    <ul>
      <li class="icons__icon">
        <div
          class="icons__container__icon-wrap flex justify--center align--center mb-1"
          [ngClass]="'background--' + icon.bgColor"
        >
          <dgx-components-icon
            [svgIcon]="icon.iconName | iconName"
          ></dgx-components-icon>
        </div>
      </li>
      <li class="icons__title">
        <h5 class="icons__title--color">{{ icon.title }}</h5>
      </li>
      <li class="p-sm">
        <p>{{ icon.description }}</p>
      </li>
    </ul>
  </div>
</div>
<ng-content></ng-content>

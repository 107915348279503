import {
  NavigationLink,
  NavigationLinkExternal,
} from '@domgen/dgx-fe-business-models';
import { CmsModuleConfig } from '@domgen/dgx-fe-content-management';
import { TrustBoxConfig } from './trustbox.types';

export interface BuildConfig {
  header: {
    home: NavigationLink;
    products: NavigationLink;
    repair: NavigationLink;
    faq: NavigationLink;
    profile?: NavigationLink;
    basket?: NavigationLink;
    myDetails?: NavigationLink;
    logout?: NavigationLink;
    myAccountHome?: NavigationLink;
    poweredBy?: NavigationLink;
  };
  footer: {
    links: NavigationLink[];
    socialLinks?: NavigationLink[];
    copyrightOwner?: string;
    protectLinks?: NavigationLink[];
    moreLinks?: NavigationLink[];
    usefulLinks?: NavigationLink[];
    legalLinks?: NavigationLink[];
  };
  links: {
    privacy: NavigationLinkExternal;
  };
  referrers?: {
    [key: string]: {
      redirect: string;
    };
  };
  myAccountLink?: string;
  catalogueApi: string;
  basketApi: string;
  imeiApi?: string;
  defaultRequestSource: string;
  dd_guarantee: string;
  domain: string;
  sales: string;
  common: string;
  wlClient: string;
  catalogueStore: string;
  action: string;
  cms_config: CmsConfig;
  trustPilot: TrustBoxConfig;
  quotePage: string;
  checkoutLandingPage: string;
  applianceDetailsPage: string;
  checkoutOrderConfirmationPage: string;
  identityApiUrl: string;
  identityApiUrlVersion: string;
  checkoutPersonalDetailsPage: string;
  checkoutDirectDebitPage: string;
  checkoutLoggedInUserPage: string;
  checkoutCardPaymentPage: string;
  selectPaymentPage: string;
  paymentOptionsPage?: string;
  forgotPasswordPage: string;
  postcodeServiceKey: string;
  postcodeServiceFind: string;
  postcodeServiceRetrieve: string;
  recaptchaSiteKey: string;
  personApi: string;
  cardPayment: {
    description: string;
    transactionSource: string;
    transactionType: string;
  };
  retryBackOffConfig: {
    maxRetries: number;
    initialInterval: number;
    maxInterval: number;
  };
  worldpay: {
    iframeHelperURL: string;
    customisation: Record<string, unknown>;
  };
  cookieProSrc: string;
  cookieProDomainScript: string;
  backToHomeButton?: {
    text: string;
    href: string;
  };
  error404: {
    contactUs: string;
    home: string;
  };
  saleReporting?: string;
  awinTrackingTestMode?: boolean;
  refreshAPI?: string;
  offerPage?: string;
  renewalPage?: string;
  domainBasedRequestSources?: DomainBasedRequestSource[];
  validLandingPages?: string[];
  sessionInactivityDurationInMinutes?: number;
  multiBasket?: boolean;
  multiBasketMaximum?: number;
  redEyeTagging?: boolean;
  production?: boolean;
  configPath: string;
  appRoot: string;
  showAutoRenewalOptOut?: boolean;
  mentionMeApi?: string;
  mentionMeReferrerOffer?: string;
  mobileModelApi?: string;
}
export interface DomainBasedRequestSource {
  pattern: RegExp;
  requestSource: string;
}
export type CmsConfig = Partial<CmsModuleConfig> & {
  logCmsRequests?: boolean;
  cachedContentAppBase?: string;
};

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CheckboxToggleDef,
  ControlType,
} from '@domgen/dgx-fe-dynamic-form-builder';

@Component({
  selector: 'wl-terms-and-conditions-form',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionsFormComponent
  implements OnInit, OnChanges, AfterViewInit
{
  field?: CheckboxToggleDef;
  formGroup: FormGroup = new FormGroup({
    isAccepted: new FormControl(false, Validators.requiredTrue),
  });

  @Input() initialValue?: boolean;
  @Input() isInsurance: boolean | null = false;
  @Input() isUpsell: boolean | null = false;
  @Input() validate = false;
  @Input() privacyPolicyUrl?: string;
  @Input() termsAndConditionsUrl: string | undefined = '';
  @Input() ipidUrl?: string;
  @Input() termsAndConditionUpsellUrl: string | undefined = '';
  @Input() ipidUpsellUrl?: string;
  @Input() alternativeLabelText?: string;

  @Output()
  form: EventEmitter<FormGroup> = new EventEmitter();

  ngOnInit(): void {
    if (this.initialValue) {
      this.formGroup.patchValue({
        isAccepted: this.initialValue,
      });
    }

    this.setFieldDefinition();
  }

  ngAfterViewInit() {
    if (this.isInsurance) {
      this.form.emit(this.formGroup);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validate?.currentValue === true) {
      this.formGroup.markAllAsTouched();
    }
  }

  private setFieldDefinition(): void {
    const defaultLabel = `
      By ticking this box, you confirm that you have read the Insurance Product Information
      Document and that this product meets your demands and needs.`;
    this.field = {
      controlType: ControlType.TOGGLE,
      controlName: 'isAccepted',
      validators: [Validators.requiredTrue],
      label: {
        text: this.alternativeLabelText
          ? this.alternativeLabelText
          : defaultLabel,
      },
      validationMessages: [
        {
          type: 'required',
          message: 'Please confirm that you have read and agree',
        },
      ],
    };
  }
}

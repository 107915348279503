import {
  BasketItemPaymentOption,
  CheckoutCompletedItem,
  CheckoutResponse,
  GaTags,
  PaymentType,
} from '@common/util-models';

export function formatCheckoutSuccessGaTag(
  response: CheckoutResponse,
  basketId: string | undefined,
  paymentType: PaymentType | undefined
): GaTags {
  const completedItems = response.completedItems;
  const value: number = calculateCheckoutItemsTotal(
    response.completedItems,
    paymentType
  );

  return {
    event: 'purchase',
    quoteType: 'dg-qb',
    customerType: 'G',
    CustomerId: '',
    DigitalID: basketId,
    companyType: 'D',
    checkoutOption: 'normal',
    orderdetails: completedItems
      .map((completedItem, index) => {
        const applianceDetails = completedItem.item.data.applianceDetails;
        return `Appliance ${index + 1}: ${applianceDetails.brandName} ${
          applianceDetails.applianceName
        } | ${paymentType} | ${calculateCheckoutItemValue(
          completedItem,
          paymentType
        )}`;
      })
      .join(' | '),
    QuoteID: completedItems
      .map((completedItem, index) => {
        const quote = completedItem.item.data.quotes[0];
        return `Quote ${index + 1}: ${quote.quoteId}`;
      })
      .join(' | '),
    ecommerce: {
      currency: 'GBP',
      value,
      transaction_id: basketId,
      items: completedItems.map((completedItem) => {
        return formatCheckoutItemGaTag(completedItem, paymentType);
      }),
    },
  };
}

export function calculateCheckoutItemsTotal(
  completedItems: CheckoutCompletedItem[],
  paymentType: PaymentType | undefined
): number {
  const total: number = completedItems.reduce(
    (total: number, completedItem: CheckoutCompletedItem) => {
      const price = calculateCheckoutItemValue(completedItem, paymentType);

      if (price) {
        return total + price;
      }
      return total;
    },
    0
  );

  return Math.round(total * 100) / 100;
}

export function calculateCheckoutItemValue(
  completedItem: CheckoutCompletedItem,
  paymentType: PaymentType | undefined
): number {
  const paymentOption: BasketItemPaymentOption | undefined =
    completedItem.item.data.quotes[0].paymentOptions.find(
      (option) => option.paymentType === paymentType
    );

  if (!paymentOption) {
    return 0;
  }

  return paymentType === PaymentType.DirectDebit
    ? paymentOption.subsequentPayment
    : paymentOption.fee;
}

export function formatCheckoutItemGaTag(
  completedItem: CheckoutCompletedItem,
  paymentType: PaymentType | undefined
): GaTags {
  const applianceDetails = completedItem.item.data.applianceDetails;
  const price = calculateCheckoutItemValue(completedItem, paymentType);

  return {
    item_brand: applianceDetails.brandName,
    item_category: applianceDetails.applianceCategory,
    item_category2: applianceDetails.applianceName,
    item_category3: applianceDetails.purchasePrice,
    item_category4: `${applianceDetails.purchaseMonth}/${applianceDetails.purchaseYear}`,
    item_category5: applianceDetails.inWarranty,
    item_id: completedItem.confirmation.planNumber,
    item_name: `${applianceDetails.brandName} ${applianceDetails.applianceName}`,
    paymentType,
    price,
    quantity: 1,
  };
}

<dgx-dfb-field-layout
  [id]="id"
  [label]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
  [hasPlumbingAndDrainage]="hasPlumbingAndDrainage"
>
  <div
    class="select-wrapper"
    [ngClass]="{ 'select-wrapper--fit-content': !!field?.fitContent }"
  >
    <select
      #exampleSelect
      class="form-control"
      [class.select--empty]="value === ''"
      [name]="field?.controlName"
      (change)="onChange(exampleSelect.value, true)"
      (focus)="onChange(exampleSelect.value, false)"
      (blur)="onChange(exampleSelect.value, false)"
      [dgxFdnQmSanitise]="field?.sanitise"
      [disabled]="disabled || (disabled$ | async)"
      [id]="id"
    >
      <option disabled [selected]="!value">
        {{ field?.placeholder }}
      </option>
      <option
        *ngFor="let item of $any(options)"
        [value]="item.value || item"
        [selected]="item.value === value || item === value"
      >
        {{ item.label || item }}
      </option>
    </select>
    <i
      *ngIf="!field?.showPreloader; else showPreloader"
      class="icon icon-arrow-down"
    ></i>
    <ng-template #showPreloader>
      <ng-container
        *ngIf="loading$ | async; else showArrowDown"
        [ngTemplateOutlet]="svgIconPreloader"
      >
      </ng-container>
      <ng-template #showArrowDown>
        <i class="icon icon-arrow-down"></i>
      </ng-template>
    </ng-template>
    <ui-form-validation-icon
      [validityStatus]="validity"
    ></ui-form-validation-icon>
  </div>
</dgx-dfb-field-layout>

<ng-template #svgIconPreloader>
  <svg
    class="select-preloader"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20.5"
  >
    <path
      class="st0"
      d="M10 2.5c4.4 0 8 3.6 8 8s-3.6 8-8 8 -8-3.6-8-8S5.6 2.5 10 2.5M10 0.5c-5.5 0-10 4.5-10 10s4.5 10 10 10c5.5 0 10-4.5 10-10S15.5 0.5 10 0.5L10 0.5z"
    />
    <path
      class="st1"
      d="M10 3c2.1 0 3.9 0.8 5.3 2.2l2.1-2.1C15.5 1.2 12.9 0 10 0 7.1 0 4.5 1.2 2.6 3.1L4.7 5.2C6.1 3.8 7.9 3 10 3z"
    />
  </svg>
</ng-template>

import {
  CheckoutCompletedItem,
  CheckoutPlanDetails,
  CoverType,
  PaymentType,
  Quote,
} from '@common/util-models';

export function mapMultiPlanDetails(
  checkedOutItems: CheckoutCompletedItem[] | undefined,
  quotes: Quote[],
  paymentDay: number | undefined,
  paymentType: PaymentType | undefined,
  selectedCoverType: CoverType | null
): CheckoutPlanDetails[] | undefined {
  if (quotes?.length && selectedCoverType) {
    const checkoutMultiPlanDetails: CheckoutPlanDetails[] = [];

    checkedOutItems?.map((checkedOutItem) => {
      const confirmation = checkedOutItem?.confirmation;
      const applianceDetails = checkedOutItem?.item.data.applianceDetails;
      const quote = checkedOutItem?.item.data.quotes.find(
        (quote) => quote.coverType === selectedCoverType
      );

      const productType = quote?.productType;
      const excessAmount = quote?.excessAmount || 0;
      const periodOfCover = quote?.periodOfCover;
      const paymentTerm = quote?.paymentTerm;
      const paymentOption = quote?.paymentOptions.find(
        (paymentOption) => paymentOption.paymentType === paymentType
      );

      checkoutMultiPlanDetails.push({
        paymentDay,
        paymentOption,
        confirmation,
        applianceDetails,
        periodOfCover,
        itemType: checkedOutItem?.item.itemType,
        paymentTerm,
        excessAmount,
        productType,
      });
    });
    return checkoutMultiPlanDetails;
  } else {
    return undefined;
  }
}

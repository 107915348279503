<div class="overlay" *ngIf="isOpenMainNav" [@fadeInOut]></div>

<header
  class="wrapper"
  [ngClass]="{
    'wrapper--wl': !isMyAccountLayout,
    'wrapper--wl-my-account': isMyAccountLayout,
    'wrapper--powered-by-logo': clientLogoPath
  }"
  data-hook="app-header"
>
  <div class="container header">
    <!-- * CLIENT LOGO * -->
    <div class="header__client-logo">
      <ui-link
        *ngIf="navigationLinks.home && !navigationLinks.home?.isHidden"
        [navigationLink]="navigationLinks.home"
        (click)="onClick($event, 'Logo')"
      >
        <img
          src="{{
            clientLogoPath ? clientLogoPath : 'assets/images/dg-logo-colour.png'
          }}"
          role="img"
          alt="Logo"
        />
      </ui-link>
    </div>

    <!-- * NAV * -->
    <div class="header__main-nav" [class.active]="isOpenMainNav">
      <dgx-ui-wl-header-nav-link
        *ngIf="navigationLinks.products && !navigationLinks.products?.isHidden"
        class="header__main-nav-link animated-border"
        [navigationLink]="navigationLinks.products"
        (clicked)="onClick($event, navigationLinks.products?.label)"
      >
      </dgx-ui-wl-header-nav-link>
      <dgx-ui-wl-header-nav-link
        *ngIf="navigationLinks.repair && !navigationLinks.repair?.isHidden"
        class="header__main-nav-link animated-border"
        [navigationLink]="navigationLinks.repair"
        (clicked)="onClick($event, navigationLinks.repair?.label)"
      >
      </dgx-ui-wl-header-nav-link>
      <dgx-ui-wl-header-nav-link
        *ngIf="navigationLinks.faq && !navigationLinks.faq?.isHidden"
        class="header__main-nav-link animated-border"
        [navigationLink]="navigationLinks.faq"
        (clicked)="onClick($event, navigationLinks.faq?.label)"
      >
      </dgx-ui-wl-header-nav-link>

      <!-- * EXTRA NAV * -->
      <ng-container *ngIf="extraNavigationLinks.length !== 0">
        <ng-container *ngFor="let extraLink of extraNavigationLinks">
          <dgx-ui-wl-header-nav-link
            *ngIf="extraLink && !extraLink?.isHidden"
            class="header__main-nav-link animated-border"
            [navigationLink]="extraLink"
            (clicked)="onClick($event, extraLink?.label)"
          >
          </dgx-ui-wl-header-nav-link
        ></ng-container>
      </ng-container>

      <!-- * MY ACCOUNT ISLOGGEDIN NAV ITEMS * -->
      <dgx-ui-wl-header-nav-link
        *ngIf="
          navigationLinks.myAccountHome &&
          !navigationLinks.myAccountHome?.isHidden &&
          isMyAccountLayout &&
          isLoggedIn
        "
        class="header__main-nav-link header__main-nav-link--my-plans animated-border"
        [navigationLink]="navigationLinks.myAccountHome"
        (clicked)="onClick($event, navigationLinks.myAccountHome?.label)"
      >
      </dgx-ui-wl-header-nav-link>
      <dgx-ui-wl-header-nav-link
        *ngIf="
          navigationLinks.myDetails &&
          !navigationLinks.myDetails?.isHidden &&
          isMyAccountLayout &&
          isLoggedIn
        "
        class="header__main-nav-link animated-border sm--hidden"
        [navigationLink]="navigationLinks.myDetails"
        (clicked)="onClick($event, navigationLinks.myDetails?.label)"
      >
      </dgx-ui-wl-header-nav-link>
      <dgx-ui-wl-header-nav-link
        *ngIf="
          navigationLinks.logout &&
          !navigationLinks.logout?.isHidden &&
          isMyAccountLayout &&
          isLoggedIn
        "
        class="header__main-nav-link animated-border sm--hidden"
        [navigationLink]="navigationLinks.logout"
        (clicked)="logoutClick()"
      >
      </dgx-ui-wl-header-nav-link>
    </div>

    <!-- * POWERED BY LOGO * -->
    <div *ngIf="clientLogoPath" class="header__powered-by">
      <dgx-ui-wl-header-powered-by
        *ngIf="
          navigationLinks.poweredBy && !navigationLinks.poweredBy?.isHidden
        "
        [navigationLink]="navigationLinks.poweredBy"
        (clicked)="onClick($event, 'D+G Logo')"
      ></dgx-ui-wl-header-powered-by>
    </div>

    <!-- * BASKET LINK * -->
    <div class="header__basket">
      <dgx-ui-wl-header-nav-link
        [navigationLink]="navigationLinks.basket"
        class="animated-border"
        *ngIf="navigationLinks.basket && !navigationLinks.basket?.isHidden"
      ></dgx-ui-wl-header-nav-link>
    </div>

    <!-- * LOGGED-IN NAV * -->
    <div
      *ngIf="isMyAccountLayout && isLoggedIn"
      class="header__logged-in-nav"
      [class.active]="isOpenLoggedIn"
    >
      <!-- * ITEMS * -->
      <dgx-ui-wl-header-nav-link
        *ngIf="
          navigationLinks.myDetails && !navigationLinks.myDetails?.isHidden
        "
        class="header__logged-in-nav-link animated-border"
        [navigationLink]="navigationLinks.myDetails"
        (clicked)="onClick($event, navigationLinks.myDetails?.label)"
      >
      </dgx-ui-wl-header-nav-link>
      <dgx-ui-wl-header-nav-link
        *ngIf="navigationLinks.logout && !navigationLinks.logout?.isHidden"
        class="header__logged-in-nav-link animated-border"
        [navigationLink]="navigationLinks.logout"
        (clicked)="logoutClick()"
      >
      </dgx-ui-wl-header-nav-link>
    </div>

    <!-- * LOGGED-IN NAV BUTTON * -->
    <button
      *ngIf="isMyAccountLayout && isLoggedIn"
      id="logged-in-button"
      class="header__button header__button--logged-in animated-border xs--hidden sm--block"
      [attr.aria-label]="
        isOpenLoggedIn ? 'Hide logged-in menu' : 'Show logged-in menu'
      "
      (click)="onToggleLoggedInMenu()"
    >
      <ui-graphic iconName="my-account" iconSize="xl"></ui-graphic>
    </button>

    <!-- * MY ACCOUNT LINK * -->
    <div *ngIf="!isMyAccountLayout" class="header__my-account">
      <dgx-ui-wl-header-nav-link
        [navigationLink]="navigationLinks.profile"
        class="animated-border"
        *ngIf="navigationLinks.profile && !navigationLinks.profile?.isHidden"
      ></dgx-ui-wl-header-nav-link>
    </div>

    <!-- * MAIN NAV BUTTON * -->
    <button
      id="menu-button"
      class="header__button header__button--main"
      [attr.aria-label]="isOpenMainNav ? 'Hide site menu' : 'Show site menu'"
      [class.active]="isOpenMainNav"
      (click)="onToggleMenu()"
    ></button>
  </div>
</header>

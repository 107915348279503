import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FeatureConfigFacade } from '@common/data-access-feature-config';
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  CommonFeatureCoreModule,
} from '@common/feature-core';
import { CommonUiSalesComponentsModule } from '@common/ui-sales-components';
import {
  ENVIRONMENT,
  GOOGLE_TAG_GLOBAL_PROPERTIES,
} from '@common/util-foundation';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { HeaderModule } from '@domgen/dgx-ui-whitelabel-components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ReinstateDataAccessReinstateModule } from '@reinstate/data-access-reinstate';
import { environment } from '@reinstate/util-environment';
import { ReinstateAppComponent } from './components/reinstate-app/reinstate-app.component';
import { featureConfigGoogleTagGlobalPropsFactory } from './feature-config-google-tag-global-factory';
import { META_REDUCERS } from './meta-reducers/meta-reducers';
import { ReinstateFeatureShellRoutingModule } from './reinstate-feature-shell-router.module';

@NgModule({
  imports: [
    CommonModule,
    SharedUiComponentsCoreModule,
    CommonUiSalesComponentsModule,
    ReinstateFeatureShellRoutingModule,
    HeaderModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production
          ? [...META_REDUCERS]
          : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    ReinstateDataAccessReinstateModule,
    EffectsModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonFeatureCoreModule,
  ],
  declarations: [ReinstateAppComponent],
  exports: [ReinstateAppComponent],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: GOOGLE_TAG_GLOBAL_PROPERTIES,
      useFactory: featureConfigGoogleTagGlobalPropsFactory,
      deps: [FeatureConfigFacade],
    },
  ],
})
export class ReinstateFeatureShellModule {
  static forRoot(
    navigationConfig: AppProvidersConfig
  ): ModuleWithProviders<ReinstateFeatureShellModule> {
    return {
      ngModule: ReinstateFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: navigationConfig },
      ],
    };
  }
}

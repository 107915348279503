import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CmsFormField,
  MarketingPreferences,
} from '@domgen/dgx-fe-business-models';
import {
  DynamicFormbuilderService,
  FieldDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { ComponentStore } from '@ngrx/component-store';
import { of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { MarketingPreferencesFormConfigService } from './marketing-preferences-form-config.service';

export interface MarketingPreferencesFormState {
  cmsFormData: CmsFormField[];
  form: FormGroup;
  formBuilderConfig: FieldDef[];
  prefilledForm: MarketingPreferences | null;
  validate: boolean;
}

const SELECTION_YES = 'yes';

@Injectable()
export class MarketingPreferencesFormStateService extends ComponentStore<MarketingPreferencesFormState> {
  // Selectors
  readonly formBuilderConfig$ = this.select(
    (state: MarketingPreferencesFormState) => state.formBuilderConfig
  );
  readonly formGroup$ = this.select(
    (state: MarketingPreferencesFormState) => state.form
  );
  readonly validate$ = this.select(
    (state: MarketingPreferencesFormState) => state.validate
  );
  readonly prefilledForm$ = this.select(
    (state: MarketingPreferencesFormState) => state.prefilledForm
  );
  readonly cmsFormData$ = this.select(
    (state: MarketingPreferencesFormState) => state.cmsFormData
  );

  readonly valueChanges$ = this.formGroup$.pipe(
    switchMap((formGroup) => {
      return formGroup.valueChanges.pipe(
        map((formValue) => ({
          email: formValue.email?.toLocaleLowerCase() === SELECTION_YES,
          phone: formValue.phone?.toLocaleLowerCase() === SELECTION_YES,
          post: formValue.post?.toLocaleLowerCase() === SELECTION_YES,
        }))
      );
    })
  );

  readonly setCmsFormData = this.updater(
    (state: MarketingPreferencesFormState, value: CmsFormField[]) => ({
      ...state,
      cmsFormData: value,
    })
  );

  readonly setPrefilledFormData = this.updater(
    (state: MarketingPreferencesFormState, value: MarketingPreferences) => ({
      ...state,
      prefilledForm: value,
    })
  );

  // *** Updater Sources ****
  private fieldDefinitionUpdater$ = this.cmsFormData$.pipe(
    switchMap((cmsData) =>
      of(cmsData).pipe(
        withLatestFrom(this.prefilledForm$),
        map(([formFieldsData, prefilledForm]) => {
          return formFieldsData.length
            ? this.formConfig.getFormbuilderConfig(
                formFieldsData,
                prefilledForm
              )
            : [];
        })
      )
    )
  );

  readonly validate = this.updater((state: MarketingPreferencesFormState) => ({
    ...state,
    validate: true,
  }));

  private readonly cmsFieldDefinitionUpdater = this.updater(
    (state: MarketingPreferencesFormState, value: FieldDef[]) => ({
      ...state,
      formBuilderConfig: value,
    })
  )(this.fieldDefinitionUpdater$);

  private formGroupUpdater$ = this.formBuilderConfig$.pipe(
    switchMap((formBuilderConfig) =>
      of(formBuilderConfig).pipe(
        map((conf) => this.dynamicFormBuilder.generateFormGroup(conf))
      )
    )
  );

  private readonly formGroupUpdater = this.updater(
    (state: MarketingPreferencesFormState, value: FormGroup) => ({
      ...state,
      form: value,
    })
  )(this.formGroupUpdater$);

  readonly vm$ = this.select(
    this.formBuilderConfig$,
    this.formGroup$,
    this.validate$,
    (fieldDef, formGroup, validate) => ({
      fieldDef,
      formGroup,
      validate,
    })
  );

  constructor(
    private formConfig: MarketingPreferencesFormConfigService,
    private dynamicFormBuilder: DynamicFormbuilderService
  ) {
    super({
      cmsFormData: [],
      form: new FormGroup({}),
      formBuilderConfig: [],
      prefilledForm: null,
      validate: false,
    });
  }
}

<form [formGroup]="formGroup" *ngIf="isInsurance">
  <wl-terms-and-conditions-for-insurance
    *ngIf="field"
    [formControlName]="field.controlName"
    [field]="field"
    [validate]="validate"
  >
    <wl-terms-and-conditions-text
      *ngIf="!isUpsell"
      [termsUrl]="termsAndConditionsUrl"
      [policyUrl]="privacyPolicyUrl"
      [isInsurance]="isInsurance"
      [productInfoUrl]="ipidUrl"
    ></wl-terms-and-conditions-text>
    <wl-terms-and-conditions-upsell-text
      *ngIf="isUpsell"
      [termsUrl]="privacyPolicyUrl"
      [appliancePolicyUrl]="termsAndConditionsUrl"
      [applianceInsuranceUrl]="ipidUrl"
      [plumbingAndDrainagePolicyUrl]="termsAndConditionUpsellUrl"
      [plumbingAndDrainageInsuranceUrl]="ipidUpsellUrl"
    ></wl-terms-and-conditions-upsell-text>
  </wl-terms-and-conditions-for-insurance>
</form>

<wl-terms-and-conditions-text
  *ngIf="!isInsurance"
  [termsUrl]="termsAndConditionsUrl"
  [policyUrl]="privacyPolicyUrl"
  [isInsurance]="isInsurance"
>
</wl-terms-and-conditions-text>

<ng-container
  [ngTemplateOutlet]="legend ? fieldsetLayout : fieldLayout"
></ng-container>

<dgx-dfb-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></dgx-dfb-error>

<div *ngIf="hint && !hasPlumbingAndDrainage" class="field-layout__hint">
  {{ hint }}
</div>
<ng-content select="[in-field-footer]"></ng-content>

<ng-template #fieldLayout>
  <div class="field-layout__header" data-automation="field-layout__header">
    <label [for]="id" [dgxFdnQmSanitise]="sanitise">
      {{ label }}
    </label>
    <dgx-dfb-tooltip
      *ngIf="tooltip"
      [tooltip]="tooltip"
      [tooltipName]="tooltipName"
    ></dgx-dfb-tooltip>
    <ng-content select="[in-field-header]"></ng-content>
  </div>

  <ng-container
    *ngIf="tooltip"
    dgxDfbTooltipTarget
    [tooltipName]="tooltipName"
  ></ng-container>

  <ng-container [ngTemplateOutlet]="layoutContent"></ng-container>
</ng-template>

<ng-template #fieldsetLayout>
  <fieldset>
    <div class="field-layout__header" data-automation="field-layout__header">
      <legend>
        {{ legend }}
      </legend>
      <dgx-dfb-tooltip
        *ngIf="tooltip"
        [tooltip]="tooltip"
        [tooltipName]="tooltipName"
      ></dgx-dfb-tooltip>
      <ng-content select="[in-field-header]"></ng-content>
    </div>

    <ng-container
      *ngIf="tooltip"
      dgxDfbTooltipTarget
      [tooltipName]="tooltipName"
    ></ng-container>

    <ng-container [ngTemplateOutlet]="layoutContent"></ng-container>
  </fieldset>
</ng-template>

<ng-template #layoutContent> <ng-content></ng-content></ng-template>

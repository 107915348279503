import { DatePipe, DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DateWithOrdinalPipe } from '@common/util-foundation';
import {
  BasketItemPaymentOption,
  CheckoutPlanDetails,
  PaymentType,
  ProductType,
} from '@common/util-models';
import { QuoteDocument } from '@domgen/dgx-fe-business-models';

import type {
  detailData,
  DetailSectionData,
  DetailSectionDataItem,
} from '@domgen/dgx-fe-components-core';
@Component({
  selector: 'wl-appliance-plan-detail',
  templateUrl: './appliance-plan-detail.component.html',
  styleUrls: ['./appliance-plan-detail.component.scss'],
  providers: [DateWithOrdinalPipe, DecimalPipe],
})
export class AppliancePlanDetailComponent implements OnChanges {
  @Input() planDetails: CheckoutPlanDetails | undefined | null;
  @Input() multiPlanDetails: CheckoutPlanDetails[] | undefined | null;
  @Input() isInsurance: boolean | null = false;
  @Input() isHeating: boolean | null = false;
  @Input() productInfo: QuoteDocument | undefined = undefined;
  @Input() terms: QuoteDocument | undefined = undefined;
  @Input() hasPlumbingAndDrainage: boolean | null = false;
  @Output() termsClick: EventEmitter<void> = new EventEmitter();

  detail: detailData[] = [];
  brand = '';
  boilerType = '';
  multiDetails: DetailSectionData[] = [];
  multiDetailsFooter: DetailSectionDataItem | null =
    {} as DetailSectionDataItem;
  multiPaymentDetails: detailData[] = [];
  multiDetailsTotalPayment = 0;

  constructor(
    private _decimalPipe: DecimalPipe,
    private _datePipe: DatePipe,
    private datePipe: DateWithOrdinalPipe
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.planDetails && !changes.multiPlanDetails) {
      return;
    }

    if (this.planDetails && !this.multiPlanDetails) {
      this.mapToDetailArray(this.planDetails);
    }

    if (this.multiPlanDetails) {
      this.mapMultiDetails(this.multiPlanDetails);
    }
  }

  mapToDetailArray(planDetails: CheckoutPlanDetails) {
    if (planDetails?.applianceDetails?.applianceName) {
      this.boilerType = planDetails.applianceDetails.applianceName;
    }
    if (planDetails?.applianceDetails?.brandName) {
      this.brand = planDetails.applianceDetails.brandName;
    }

    this.detail = [
      {
        key: `Your ${this.isInsurance ? 'policy' : 'plan'} number`,
        value: planDetails?.confirmation?.planNumber,
      },
    ];
    const paymentOption = planDetails?.paymentOption;
    if (
      paymentOption &&
      paymentOption.paymentType === PaymentType.DirectDebit
    ) {
      const value = this.addMonthlyPayment(paymentOption);

      this.detail.push({
        key: 'Monthly payments',
        value,
      });

      this.detail.push({
        key: 'Payment method',
        value: 'Direct debit',
      });

      if (this.planDetails?.paymentDay) {
        const day = this.getDaySuffix(this.planDetails.paymentDay);
        const company = this.isInsurance
          ? ' Domestic & General Insurance PLC'
          : 'Domestic & General Services Limited';

        const monthCountText =
          paymentOption?.numPayments === 0
            ? ''
            : `for a total of ${paymentOption.numPayments} months`;

        this.detail.push({
          key: 'Monthly payment date',
          value: `The ${day} of every month ${monthCountText} to ${company}.<br/><br/>If your chosen payment date is within the next 14 days, your first and second payments will be taken at the same time next month.`,
        });
      }
    } else if (paymentOption) {
      this.detail.push({
        key: 'One-time payment',
        value: `One-time payment of £${this._decimalPipe.transform(
          paymentOption.fee,
          '1.2-2'
        )}`,
      });
      this.detail.push({
        key: 'Payment method',
        value: 'Credit or debit card',
      });
    }

    if (planDetails?.confirmation?.breakdownStartDate) {
      const planStartDateObj = {
        key: `${this.isInsurance ? 'Policy' : 'Plan'} starting date`,
        value: this._datePipe.transform(
          planDetails.confirmation.breakdownStartDate,
          'dd/MM/yy'
        ),
      };
      this.detail.splice(2, 0, planStartDateObj);
    }
  }

  mapMultiDetails(multiPlanDetails: CheckoutPlanDetails[]) {
    this.multiDetailsTotalPayment = 0;
    const paymentOption = multiPlanDetails[0]?.paymentOption;
    this.multiDetails = this.createMultiDetail(multiPlanDetails);
    const multiPaymentDetailsForPlumbingAndDrainage =
      this.createDirectDebitDetailsForPlumbingAndDrainage(multiPlanDetails);
    const multiPaymentDetails =
      paymentOption?.paymentType === PaymentType.DirectDebit
        ? this.createDirectDebitDetails(multiPlanDetails)
        : this.createCreditCardDetails(multiPlanDetails);

    this.multiPaymentDetails = this.hasPlumbingAndDrainage
      ? multiPaymentDetailsForPlumbingAndDrainage
      : multiPaymentDetails;
    this.multiDetailsFooter =
      this.addMultiItemTotalMonthlyPaymentDetails(paymentOption);
  }

  getDaySuffix(day: number) {
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), day);
    return this.datePipe.transform(date, 'do');
  }

  addMonthlyPayment(paymentOption: BasketItemPaymentOption): string {
    if (paymentOption?.numPayments === 0) {
      return `£${this._decimalPipe.transform(
        paymentOption.subsequentPayment,
        '1.2-2'
      )} a month`;
    }
    if (paymentOption.discount) {
      return `
        First ${
          paymentOption.discountPeriod
        } monthly payments of £${this._decimalPipe.transform(
        paymentOption.firstDiscountPayment,
        '1.2-2'
      )},<br/>
        then ${
          paymentOption.numPayments - (paymentOption.discountPeriod || 0)
        } monthly payments of £${this._decimalPipe.transform(
        paymentOption.subsequentDiscountPayment,
        '1.2-2'
      )}
      `;
    }
    if (paymentOption.firstPayment !== paymentOption.subsequentPayment) {
      return `1 payment of £${this._decimalPipe.transform(
        paymentOption.firstPayment,
        '1.2-2'
      )}<br/>then ${
        paymentOption.numSubsequentPayments
      } monthly payments of £${this._decimalPipe.transform(
        paymentOption.subsequentPayment,
        '1.2-2'
      )}`;
    }
    return `${
      (paymentOption.numSubsequentPayments || 0) + 1
    } monthly payments of £${this._decimalPipe.transform(
      paymentOption.subsequentPayment,
      '1.2-2'
    )}`;
  }

  addMultiItemMonthlyPayment(paymentOption: BasketItemPaymentOption): string {
    const payment =
      paymentOption.paymentType === PaymentType.DirectDebit
        ? paymentOption.subsequentPayment
        : paymentOption.fee;
    this.multiDetailsTotalPayment += payment;
    return `£${this._decimalPipe.transform(payment, '1.2-2')}`;
  }

  addMultiItemTotalMonthlyPaymentDetails(
    paymentOption: BasketItemPaymentOption | undefined
  ) {
    if (this.isHeating) {
      return paymentOption?.paymentType === PaymentType.DirectDebit
        ? {
            key: `${paymentOption.numPayments} monthly payments of`,
            value: `£${this._decimalPipe.transform(
              paymentOption.subsequentPayment,
              '1.2-2'
            )}`,
            subtitle: `Total cost £${this._decimalPipe.transform(
              paymentOption.subsequentPayment * paymentOption.numPayments,
              '1.2-2'
            )}`,
          }
        : null;
    }
    return {
      key: 'Total',
      value: `£${this._decimalPipe.transform(
        this.multiDetailsTotalPayment,
        '1.2-2'
      )} a month`,
    };
  }

  createCreditCardDetails(multiPlanDetails: CheckoutPlanDetails[]) {
    const payment = this.isHeating
      ? multiPlanDetails[0].paymentOption?.fee
      : this.multiDetailsTotalPayment;
    return [
      {
        key: 'Payment method',
        value: 'Credit or debit card',
      },
      {
        key: 'One-time payment',
        value: `One-time payment of £${this._decimalPipe.transform(
          payment,
          '1.2-2'
        )}`,
      },
    ];
  }

  createDirectDebitDetailsForPlumbingAndDrainage([
    multiPlanDetails,
  ]: CheckoutPlanDetails[]) {
    const paymentDetails = [
      {
        key: 'Payment method',
        value: 'Direct Debit',
      },
      {
        key: 'Monthly payments',
        value:
          'This policy provides continuous cover, so your monthly payments will continue for as long as you like. You can choose to cancel at any time by phone, online or by post.',
      },
      {
        key: 'Monthly payment date',
        value: `Day ${multiPlanDetails.paymentDay} of each month, to Domestic & General Insurance PLC.<br/><br/> Your first payment will be taken on your chosen date or 14 days after we process your details, whichever one is later.`,
      },
      {
        key: 'Cover start date',
        value: `This policy has a 30 day wait, so your cover will start on ${this._datePipe.transform(
          multiPlanDetails.confirmation?.breakdownStartDate,
          'dd MMMM yyyy'
        )}. This start date is also on the policy documents you'll receive from us shortly.`,
      },
    ];
    return paymentDetails;
  }

  createDirectDebitDetails(multiPlanDetails: CheckoutPlanDetails[]) {
    const company = this.isInsurance
      ? ' Domestic & General Insurance PLC'
      : 'Domestic & General Services Limited';

    const monthCountText =
      multiPlanDetails[0]?.paymentOption?.numPayments === 0
        ? ''
        : `for a total of ${multiPlanDetails[0].paymentOption?.numPayments} months`;
    let paymentDetails = [
      {
        key: 'Payment method',
        value: 'Direct debit',
      },
    ];
    paymentDetails = this.isHeating
      ? paymentDetails
      : [
          ...paymentDetails,
          {
            key: 'Monthly payments',
            value:
              'All appliance purchases will be billed as separate monthly Direct Debits and will therefore appear separately on your bank statement. This also means you can choose to cancel any of the appliance policies at any time, if you want to.',
          },
        ];
    return multiPlanDetails[0].paymentDay
      ? [
          ...paymentDetails,
          {
            key: 'Monthly payment date',
            value: `Day ${
              multiPlanDetails[0].paymentDay
            } of each month.<br/><br/> The ${this.getDaySuffix(
              multiPlanDetails[0].paymentDay
            )} of every month ${monthCountText} to ${company}.<br/><br/>If your chosen payment date is within the next 14 days, your first and second payments will be taken at the same time next month.`,
          },
        ]
      : paymentDetails;
  }

  createMultiDetail(
    multiPlanDetails: CheckoutPlanDetails[]
  ): DetailSectionData[] {
    return multiPlanDetails.reduce((acc, planDetails): DetailSectionData[] => {
      const productType =
        planDetails.productType === ProductType.PD
          ? {
              key: 'productType',
              value: planDetails.productType || null,
            }
          : undefined;

      const detailsList = planDetails?.confirmation?.planNumber
        ? [
            {
              key: `${this.isInsurance ? 'Policy' : 'Plan'} number`,
              value: planDetails.confirmation?.planNumber,
              sanitise: 'encrypt',
            },
          ]
        : [];
      const details = planDetails?.confirmation?.breakdownStartDate
        ? [
            ...detailsList,
            {
              key: `${this.isInsurance ? 'Cover' : 'Plan'} start date`,
              value: this._datePipe.transform(
                planDetails.confirmation.breakdownStartDate,
                'dd/MM/yy'
              ),
            },
          ]
        : detailsList;
      return [
        ...acc,
        {
          title: `${planDetails.applianceDetails?.brandName} ${planDetails.applianceDetails?.applianceName}`,
          details,
          aside:
            planDetails.paymentOption && planDetails.paymentOption.paymentType
              ? {
                  key: this.addMultiItemMonthlyPayment(
                    planDetails.paymentOption
                  ),
                  value:
                    planDetails.paymentOption.paymentType ===
                    PaymentType.DirectDebit
                      ? 'a month'
                      : '',
                }
              : {
                  key: '',
                  value: null,
                },
          excess: {
            key: planDetails.excessAmount ? 'excess' : 'No excess',
            value: planDetails.excessAmount
              ? `£${planDetails.excessAmount}`
              : '',
          },
          productType,
        },
      ];
    }, [] as DetailSectionData[]);
  }
}

<div>
  For your own benefit and protection, please read the
  <a
    *ngIf="!!termsUrl; else termsAndConditionsLabel"
    class="link"
    target="_blank"
    [href]="termsUrl"
    download
    title="Terms & Conditions"
  >
    <ng-container [ngTemplateOutlet]="termsAndConditionsLabel"></ng-container
  ></a>
  <ng-template #termsAndConditionsLabel>
    {{ isInsurance ? 'Policy ' : 'Plan ' }}Terms and Conditions</ng-template
  >

  and

  <a
    *ngIf="!!policyUrl; else privacyPolicyLabel"
    class="link"
    target="_blank"
    [href]="policyUrl"
  >
    <ng-container [ngTemplateOutlet]="privacyPolicyLabel"></ng-container>
  </a>
  <ng-template #privacyPolicyLabel>Privacy Policy</ng-template>

  carefully before purchase.

  <ng-container *ngIf="isInsurance && !!productInfoUrl">
    You must read the
    <a
      class="link"
      target="_blank"
      download
      [href]="productInfoUrl"
      title="Insurance Product Information Document"
    >
      Insurance Product Information Document</a
    >
    before purchase.
  </ng-container>
</div>

import { Injectable } from '@angular/core';
import { retryPayingByDirectDebit } from '@common/data-access-shared';
import { CheckoutBasket, CoverPaymentOptions } from '@common/util-models';
import { filterNullUndefined } from '@domgen/dgx-fe-common';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as CheckoutBasketActions from './checkout-basket.actions';
import { CheckoutBasketPartialState } from './checkout-basket.reducer';
import * as CheckoutBasketSelectors from './checkout-basket.selectors';
import {
  getCheckoutBasketState,
  getDDNumPayments,
} from './checkout-basket.selectors';

@Injectable()
export class CheckoutBasketFacade {
  basketId$: Observable<string> = this.store$.pipe(
    select(CheckoutBasketSelectors.getBasketId),
    filterNullUndefined()
  );

  getBasket$: Observable<CheckoutBasket | undefined> = this.store$.select(
    getCheckoutBasketState
  );

  paymentOptions$: Observable<CoverPaymentOptions> = this.store$.pipe(
    select(CheckoutBasketSelectors.getPaymentOptions),
    filterNullUndefined()
  );

  totalPaymentTimeInMonth$: Observable<number> = this.store$
    .select(getDDNumPayments)
    .pipe(filterNullUndefined());

  constructor(private store$: Store<CheckoutBasketPartialState>) {}

  proceedToCheckout() {
    this.store$.dispatch(CheckoutBasketActions.proceedToCheckout());
  }

  retryDirectDebitPayment() {
    this.store$.dispatch(retryPayingByDirectDebit());
  }

  setBasket(basket: CheckoutBasket) {
    this.store$.dispatch(CheckoutBasketActions.setBasket({ basket }));
  }
}

import {
  BasketItemPaymentOption,
  GaTags,
  PaymentType,
  SelectedBasketItemApplianceQuote,
} from '@common/util-models';

export function formatAddToSelectedItemsGaTag(
  selectedBasketItemApplianceQuote: SelectedBasketItemApplianceQuote
): GaTags {
  return {
    event: 'add_to_cart',
    ecommerce: {
      items: [formatSelectedItemGaTag(selectedBasketItemApplianceQuote)],
    },
  };
}

export function formatRemoveFromSelectedItemsGaTag(
  selectedBasketItemApplianceQuote: SelectedBasketItemApplianceQuote
): GaTags {
  return {
    event: 'remove_from_cart',
    ecommerce: {
      items: [formatSelectedItemGaTag(selectedBasketItemApplianceQuote)],
    },
  };
}

export function formatSelectedItemGaTag(
  selectedBasketItemApplianceQuote: SelectedBasketItemApplianceQuote
): GaTags {
  const quoteDetails = selectedBasketItemApplianceQuote.quote;
  const applianceDetails = selectedBasketItemApplianceQuote.applianceDetails;
  const paymentOption: BasketItemPaymentOption = quoteDetails.paymentOptions[0];
  const price: number =
    paymentOption.paymentType === PaymentType.DirectDebit
      ? paymentOption.subsequentPayment
      : paymentOption.fee;

  return {
    item_brand: applianceDetails.brandName,
    item_category: applianceDetails.applianceCategory,
    item_category2: applianceDetails.applianceName,
    item_category3: applianceDetails.purchasePrice,
    item_category4: `${applianceDetails.purchaseMonth}/${applianceDetails.purchaseYear}`,
    item_category5: applianceDetails.inWarranty,
    item_id: quoteDetails.schemeCode,
    item_name: `${applianceDetails.brandName} ${applianceDetails.applianceName}`,
    price,
    quantity: 1,
  };
}

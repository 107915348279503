<div>
  <p>
    For your own benefit and protection, please read the
    <a
      class="link"
      target="_blank"
      [href]="termsUrl"
      download
      title="Privacy Policy"
    >
      Privacy Policy
    </a>
    and the terms and conditions for both products carefully before purchase:
  </p>
  <ul class="mb-2">
    <li>
      Appliance protection —
      <a
        class="link"
        target="_blank"
        [href]="appliancePolicyUrl"
        download
        title="Policy Terms and Conditions"
        >Policy Terms and Conditions
      </a>
    </li>
    <li>
      Plumbing and drainage —
      <a
        class="link"
        target="_blank"
        [href]="plumbingAndDrainagePolicyUrl"
        download
        title="Policy Terms and Conditions"
        >Policy Terms and Conditions
      </a>
    </li>
  </ul>
  <p>
    You must read the Insurance Product Information Documents for both products
    before purchase:
  </p>
  <ul>
    <li>
      Appliance protection —
      <a
        class="link"
        target="_blank"
        [href]="applianceInsuranceUrl"
        download
        title="Insurance Product Information Document"
        >Insurance Product Information Document
      </a>
    </li>
    <li>
      Plumbing and drainage —
      <a
        class="link"
        target="_blank"
        [href]="plumbingAndDrainageInsuranceUrl"
        download
        title="Insurance Product Information Document"
        >Insurance Product Information Document
      </a>
    </li>
  </ul>
</div>

import { Injectable } from '@angular/core';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';
import {
  CorrespondenceAddress,
  DirectDebit,
  Person,
} from '@common/util-models';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as UserProfilePageSelectors from './user-profile-page.selectors';
import * as UserProfileActions from './user-profile.actions';
import { UserProfilePartialState } from './user-profile.reducer';
import * as UserProfileSelectors from './user-profile.selectors';

@Injectable()
export class UserProfileFacade {
  yourDetails$ = this.store.pipe(
    select(UserProfilePageSelectors.getYourDetails)
  );

  getLoggedInAddress$: Observable<CorrespondenceAddress> = this.store.select(
    UserProfilePageSelectors.getAddress
  );

  personalDetails$ = this.store.pipe(
    select(UserProfilePageSelectors.personalDetails)
  );

  personDetails$: Observable<Person | undefined> = this.store.select(
    UserProfileSelectors.getPersonalDetails
  );

  selectedPayment$ = this.store.pipe(
    select(SelectPaymentSelectors.getSelectedPaymentType)
  );

  paymentDetails$ = this.store.pipe(
    select(UserProfilePageSelectors.getPaymentDetails)
  );

  isPaperlessPlanDocumentPreferenceSet$ = this.store.pipe(
    select(UserProfileSelectors.isPaperlessPlanDocumentPreferenceSet)
  );

  constructor(
    private store: Store<UserProfilePartialState & SelectPaymentPartialState>
  ) {}

  loginSuccess() {
    this.store.dispatch(UserProfileActions.loginSuccess());
  }

  proceedCheckoutAsGuest() {
    this.store.dispatch(UserProfileActions.checkoutAsGuest());
  }

  saveNewDirectDebitDetails(newDirectDebitDetails: DirectDebit | null) {
    this.store.dispatch(
      UserProfileActions.updateDirectDebit({ newDirectDebitDetails })
    );
  }

  changePaymentDay(paymentDay: number) {
    this.store.dispatch(UserProfileActions.changePaymentDay({ paymentDay }));
  }

  savePlanDocumentPreference(byPost: boolean) {
    this.store.dispatch(
      UserProfileActions.updatePlanDocumentPreference({ paperless: !byPost })
    );
  }
}

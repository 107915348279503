import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';

import { ViewportScroller } from '@angular/common';
import {
  DirectDebit,
  LoggedInUserFormField,
  LoggedInUserFormFields,
  PaymentDetails,
} from '@common/util-models';
import * as CmsHelpers from '@domgen/dgx-fe-business-components';
import { CmsFormField, WrappedValidate } from '@domgen/dgx-fe-business-models';
import { combineLatestObj } from '@domgen/dgx-fe-common';
import {
  DynamicFormbuilderService,
  FieldDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import {
  asyncScheduler,
  combineLatest,
  merge,
  Observable,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  observeOn,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  DEFAULT_PAYMENT_DAY,
  SavedDirectDebitDetailsFormConfigService,
} from './saved-direct-debit-details-config.service';

export enum DisplayMode {
  ReadOnly = 'ReadOnly',
  Editable = 'Editable',
}

export interface DirectDebitDetailsState {
  changedPaymentDetails?: DirectDebit | null;
  cmsFormData?: LoggedInUserFormFields;
  displayMode: DisplayMode;
  editableForm?: FormGroup;
  fieldDef?: FieldDef[];
  invalidPaymentDetailsEntered: boolean;
  paymentDay?: number;
  readonlyForm?: FormGroup;
  savedPaymentDetails?: PaymentDetails;
  validate: WrappedValidate;
}

export const initialState: DirectDebitDetailsState = {
  displayMode: DisplayMode.ReadOnly,
  invalidPaymentDetailsEntered: false,
  validate: { validate: false },
};

export interface ViewModel {
  displayMode: DisplayMode;
  savedPaymentDetails: PaymentDetails;
  showCancelChangesButton: boolean;
  paymentDay: number;
  paymentDayLabel: string;
  cmsFormData: LoggedInUserFormFields;
  readonlyFormFieldDef: FieldDef[];
  readonlyForm: FormGroup;
  nameLabel: string;
  accountNumberLabel: string;
  sortcodeLabel: string;
  newPaymentDetailsId: string;
  validate: boolean;
  invalidPaymentDetailsEntered: boolean;
}

@Injectable()
export class SavedDirectDebitDetailsStateService extends ComponentStore<DirectDebitDetailsState> {
  newPaymentDetailsId = 'newPaymentDetails';

  // ****** Signals (That trigger a state change or a side effect, but do not end in the state)
  private changePaymentDetailsSubject = new Subject<unknown>();
  private cancelChangePaymentDetailsSubject = new Subject<unknown>();
  private cmsFormDataSubject = new Subject<LoggedInUserFormFields>();
  private editableFormSubject = new Subject<FormGroup>();
  private savedPaymentDetailsSubject = new Subject<PaymentDetails>();
  private validateSubject = new Subject<unknown>();
  private invalidPaymentDetailsEnteredSubject = new Subject<boolean>();

  // ************ Selectors (Normally used as vm$ streams ) **********
  private readonly displayMode$ = this.select(
    (state: DirectDebitDetailsState) => state.displayMode
  );

  private readonly invalidPaymentDetailsEntered$ = this.select(
    (state: DirectDebitDetailsState) => state.invalidPaymentDetailsEntered
  );

  private readonly validate$ = this.select(
    (state: DirectDebitDetailsState) => state.validate.validate
  );

  private readonly fieldDef$ = this.select(
    (state: DirectDebitDetailsState) => state.fieldDef
  ).pipe(filter((fieldDef) => !!fieldDef)) as Observable<FieldDef[]>;

  private readonly savedPaymentDetails$ = this.select(
    (state: DirectDebitDetailsState) => state.savedPaymentDetails
  ).pipe(
    filter((savedPaymentDetails) => !!savedPaymentDetails)
  ) as Observable<PaymentDetails>;

  private readonly showCancelChangesButton$ = this.savedPaymentDetails$.pipe(
    map((savedPaymentDetails) =>
      this.paymentDetailsPresent(savedPaymentDetails) ? true : false
    )
  );

  private readonly cmsFormData$ = this.select(
    (state: DirectDebitDetailsState) => state.cmsFormData
  ).pipe(
    filter((cmsFormData) => !!cmsFormData)
  ) as Observable<LoggedInUserFormFields>;

  private readonly nameLabel$ = this.cmsFormData$.pipe(
    map((cmsFormData) =>
      this.getFieldLabel(cmsFormData, LoggedInUserFormField.AccountName)
    )
  );

  private readonly accountNumberLabel$ = this.cmsFormData$.pipe(
    map((cmsFormData) =>
      this.getFieldLabel(cmsFormData, LoggedInUserFormField.AccountNumber)
    )
  );

  private readonly sortcodeLabel$ = this.cmsFormData$.pipe(
    map((cmsFormData) =>
      this.getFieldLabel(cmsFormData, LoggedInUserFormField.SortCode)
    )
  );

  private readonly paymentDayLabel$ = this.cmsFormData$.pipe(
    map((cmsFormData) =>
      this.getFieldLabel(cmsFormData, LoggedInUserFormField.MonthlyPaymentDay)
    )
  );

  private readonly editableForm$ = this.select(
    (state: DirectDebitDetailsState) => state.editableForm
  ).pipe(
    filter(
      (formGroup) => !!formGroup && !!Object.keys(formGroup.controls).length
    )
  ) as Observable<FormGroup>;

  private readonly readonlyForm$ = this.select(
    (state: DirectDebitDetailsState) => state.readonlyForm
  ).pipe(filter((formGroup) => !!formGroup)) as Observable<FormGroup>;

  // ************ Public Selectors (Output() events) **********
  readonly form$ = merge(this.editableForm$, this.readonlyForm$).pipe(
    observeOn(asyncScheduler)
  );

  readonly changePaymentDetails$ =
    this.changePaymentDetailsSubject.asObservable();

  readonly paymentDay$ = this.select(
    (state: DirectDebitDetailsState) => state.paymentDay
  ).pipe(
    filter((paymentDay) => paymentDay !== undefined)
  ) as Observable<number>;

  readonly changedPaymentDetails$ = this.select(
    (state: DirectDebitDetailsState) => state.changedPaymentDetails
  ).pipe(
    filter((changedPaymentDetails) => changedPaymentDetails !== undefined)
  ) as Observable<DirectDebit | null>;

  // *************** vm$ ************** //
  readonly vm$ = combineLatestObj({
    accountNumberLabel: this.accountNumberLabel$,
    cmsFormData: this.cmsFormData$,
    displayMode: this.displayMode$,
    invalidPaymentDetailsEntered: this.invalidPaymentDetailsEntered$,
    nameLabel: this.nameLabel$,
    newPaymentDetailsId: this.newPaymentDetailsId,
    paymentDay: this.paymentDay$,
    paymentDayLabel: this.paymentDayLabel$,
    readonlyForm: this.readonlyForm$,
    readonlyFormFieldDef: this.fieldDef$,
    savedPaymentDetails: this.savedPaymentDetails$,
    showCancelChangesButton: this.showCancelChangesButton$,
    sortcodeLabel: this.sortcodeLabel$,
    validate: this.validate$,
  });

  // *********** Updater Streams *********** //
  private cancelChangePaymentDetailsHandler$ =
    this.cancelChangePaymentDetailsSubject.pipe(
      withLatestFrom(
        this.paymentDay$,
        this.cmsFormData$,
        this.savedPaymentDetails$
      ),
      map(([, paymentDay, cmsFormData, paymentDetails]) => {
        return this.getReadOnlyFormAndFieldDef(cmsFormData, {
          ...paymentDetails,
          paymentDay,
        });
      })
    );

  private readonly cmsFormDataHandler$ = combineLatest([
    this.cmsFormDataSubject,
    this.savedPaymentDetailsSubject,
  ]).pipe(
    map(([cmsFormData, paymentDetails]) => ({
      ...this.getReadOnlyFormAndFieldDef(cmsFormData, paymentDetails),
      cmsFormData,
    }))
  );

  private readonly savedPaymentDetailsHandler$ =
    this.savedPaymentDetailsSubject.pipe(
      map((savedPaymentDetails) => {
        const displayMode = this.paymentDetailsPresent(savedPaymentDetails)
          ? DisplayMode.ReadOnly
          : DisplayMode.Editable;
        return { savedPaymentDetails, displayMode };
      })
    );

  private readonly changePaymentDetailsHandler$ = this.editableFormSubject.pipe(
    switchMap((formGroup) =>
      this.dynamicFormBuilder
        .selectValidFormValue$<{
          [LoggedInUserFormField.AccountName]: string;
          [LoggedInUserFormField.AccountNumber]: string;
          [LoggedInUserFormField.SortCode]: string;
          [LoggedInUserFormField.MonthlyPaymentDay]: string;
        }>(formGroup)
        .pipe(
          map(
            (formValue) =>
              ({
                accountName: formValue.accountName,
                accountNumber: formValue.accountNumber,
                sortCode: formValue.sortCode,
                monthlyPaymentDay: Number(formValue.monthlyPaymentDay),
              } as DirectDebit)
          )
        )
    )
  );

  private readonly changePaymentDayHandler$ = this.readonlyForm$.pipe(
    switchMap((readonlyForm) => {
      return this.dynamicFormBuilder
        .selectValidFormValue$<{
          [LoggedInUserFormField.MonthlyPaymentDay]: string;
        }>(readonlyForm)
        .pipe(map((paymentDay) => Number(paymentDay.monthlyPaymentDay)));
    })
  );

  // *********** Updaters *********** //
  readonly updatePaymentDetailsDisplayModePaymentDay = this.updater(
    (
      state: DirectDebitDetailsState,
      {
        savedPaymentDetails,
        displayMode,
      }: {
        savedPaymentDetails: PaymentDetails;
        displayMode: DisplayMode;
      }
    ) => {
      return {
        ...state,
        savedPaymentDetails: savedPaymentDetails,
        displayMode: displayMode,
        paymentDay: savedPaymentDetails.paymentDay || DEFAULT_PAYMENT_DAY,
      };
    }
  )(this.savedPaymentDetailsHandler$);

  readonly updateCmsFormDataFieldDef = this.updater(
    (
      state: DirectDebitDetailsState,
      {
        cmsFormData,
        fieldDef,
        readonlyForm,
      }: {
        cmsFormData: LoggedInUserFormFields;
        fieldDef: FieldDef[];
        readonlyForm: FormGroup;
      }
    ) => {
      return {
        ...state,
        cmsFormData,
        fieldDef,
        readonlyForm,
      };
    }
  )(this.cmsFormDataHandler$);

  readonly updateEditableForm = this.updater(
    (state: DirectDebitDetailsState, editableForm: FormGroup) => {
      return {
        ...state,
        editableForm,
      };
    }
  )(this.editableFormSubject);

  readonly validateForm = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state: DirectDebitDetailsState, _validate: unknown) => {
      return {
        ...state,
        validate: { validate: true },
      };
    }
  )(this.validateSubject);

  readonly updateInvalidPaymentDetailsCmsFormData = this.updater(
    (state: DirectDebitDetailsState, invalidPaymentDetailsEntered: boolean) => {
      return {
        ...state,
        invalidPaymentDetailsEntered,
        cmsFormData: invalidPaymentDetailsEntered
          ? [...(state.cmsFormData as LoggedInUserFormFields)]
          : state.cmsFormData,
      };
    }
  )(this.invalidPaymentDetailsEnteredSubject);

  readonly updateChangedPaymentDetails = this.updater(
    (state: DirectDebitDetailsState, changedPaymentDetails: DirectDebit) => {
      return {
        ...state,
        changedPaymentDetails,
      };
    }
  )(this.changePaymentDetailsHandler$);

  readonly updatePaymentDay = this.updater(
    (state: DirectDebitDetailsState, paymentDay: number) => {
      return {
        ...state,
        paymentDay,
      };
    }
  )(this.changePaymentDayHandler$);

  private readonly setReadOnlyModeResetValidateAndFormFieldDef = this.updater(
    (
      state: DirectDebitDetailsState,
      {
        fieldDef,
        readonlyForm,
      }: {
        fieldDef: FieldDef[];
        readonlyForm: FormGroup;
      }
    ) => {
      return {
        ...state,
        changedPaymentDetails: null,
        displayMode: DisplayMode.ReadOnly,
        validate: { validate: false },
        fieldDef,
        readonlyForm,
      };
    }
  )(this.cancelChangePaymentDetailsHandler$);

  private readonly setEditableModeResetValidate = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state: DirectDebitDetailsState, _value: unknown) => {
      return {
        ...state,
        displayMode: DisplayMode.Editable,
        validate: { validate: false },
      };
    }
  )(this.changePaymentDetailsSubject);

  // *********** Side Effects (Should not update state) *********** //
  readonly scrollToViewSideEffect = this.effect(
    (trigger$: Observable<boolean>) => {
      return trigger$.pipe(
        filter(
          (invalidPaymentDetailsEntered) => !!invalidPaymentDetailsEntered
        ),
        tap(() =>
          this.viewportScroller.scrollToAnchor(this.newPaymentDetailsId)
        )
      );
    }
  )(this.invalidPaymentDetailsEnteredSubject);

  constructor(
    private dynamicFormBuilder: DynamicFormbuilderService,
    private formConfigService: SavedDirectDebitDetailsFormConfigService,
    private viewportScroller: ViewportScroller
  ) {
    super(initialState);
  }

  changePaymentDetails() {
    this.changePaymentDetailsSubject.next(undefined);
  }

  cancelChangePaymentDetails() {
    this.cancelChangePaymentDetailsSubject.next(undefined);
  }

  updateCmsFormData(cmsFormData: LoggedInUserFormFields) {
    this.cmsFormDataSubject.next(cmsFormData);
  }

  setEditableForm(formGroup: FormGroup) {
    this.editableFormSubject.next(formGroup);
  }

  updateSavedPaymentDetails(paymentDetails: PaymentDetails) {
    this.savedPaymentDetailsSubject.next(paymentDetails);
  }

  invalidPaymentDetailsEntered(invalidPaymentDetailsEntered: boolean) {
    this.invalidPaymentDetailsEnteredSubject.next(invalidPaymentDetailsEntered);
  }

  validate() {
    this.validateSubject.next(undefined);
  }

  private paymentDetailsPresent(paymentDetails: PaymentDetails) {
    return paymentDetails.accountNumber && paymentDetails.sortCode;
  }

  private getFieldLabel(
    cmsFormData: LoggedInUserFormFields,
    fieldName: LoggedInUserFormField
  ) {
    return CmsHelpers.getFormField<CmsFormField>(cmsFormData, fieldName).label;
  }

  private getReadOnlyFormAndFieldDef(
    cmsFormData: LoggedInUserFormFields,
    paymentDetails: PaymentDetails
  ) {
    const fieldDef = this.formConfigService.getReadonlyFormFormbuilderConfig(
      cmsFormData,
      paymentDetails
    );
    const readonlyForm = this.dynamicFormBuilder.generateFormGroup(fieldDef);
    return { readonlyForm, fieldDef };
  }
}

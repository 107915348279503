<div
  class="form-group__result scroll"
  [ngClass]="{
    'form-group__result--all-options': showAllOptions,
    'form-group__result--has-error': hasError,
    'form-group__result--has-scroll': hasScroll
  }"
>
  <button
    *ngFor="let option of options; let idx = index"
    type="button"
    class="form-group__result-button"
    [dgxFdnQmSanitise]="sanitise"
    [id]="optionId + '-' + idx"
    [class.active]="idx === activeOptionIndex"
    (mouseenter)="onMouseEnter(idx)"
    (click)="onClick(idx)"
  >
    <dgx-dfb-hightlighted-text
      [text]="option"
      [highlightText]="hightlightedText"
    ></dgx-dfb-hightlighted-text>
  </button>
</div>

<div class="banner p-md" [style.max-width]="maxWidth ? maxWidth : ''">
  <div class="flex">
    <div class="flex quote-icon__container" *ngIf="iconName">
      <div class="quote__icon-wrap flex justify--center align--center mb-1">
        <dgx-components-icon
          [svgIcon]="iconName | iconName"
        ></dgx-components-icon>
      </div>
    </div>
    <div class="flex quote-icon__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ContractType,
  LoggedInUserFormFields,
  PaymentDetails,
  WrappedInvalidPaymentDetails,
} from '@common/util-models';
import { WrappedValidate } from '@domgen/dgx-fe-business-models';
import { SavedDirectDebitDetailsFormConfigService } from './saved-direct-debit-details-config.service';
import { SavedDirectDebitDetailsStateService } from './saved-direct-debit-details-state.service';

@Component({
  selector: 'sales-saved-direct-debit-details',
  templateUrl: './saved-direct-debit-details.component.html',
  styleUrls: ['./saved-direct-debit-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SavedDirectDebitDetailsStateService,
    SavedDirectDebitDetailsFormConfigService,
  ],
})
export class SavedDirectDebitDetailsComponent {
  @Input() isHeating = false;
  @Input() paymentDetailsEditionEnabled = true;
  @Input() productType: ContractType = ContractType.Insurance;

  @Input() set validate(validate: WrappedValidate | null) {
    if (validate?.validate) {
      this.directDebitDetailsStateService.validate();
    }
  }

  @Input() set invalidPaymentDetailsEntered(
    invalidPaymentDetailsEntered: WrappedInvalidPaymentDetails | null
  ) {
    if (invalidPaymentDetailsEntered) {
      this.directDebitDetailsStateService.invalidPaymentDetailsEntered(
        !!invalidPaymentDetailsEntered.invalidPaymentDetails
      );
    }
  }

  @Input() set cmsFormData(value: LoggedInUserFormFields | null) {
    if (value) {
      this.directDebitDetailsStateService.updateCmsFormData(value);
    }
  }

  @Input() set paymentDetails(value: PaymentDetails | null) {
    if (value) {
      this.directDebitDetailsStateService.updateSavedPaymentDetails(value);
    }
  }

  @Output() paymentDay = this.directDebitDetailsStateService.paymentDay$;

  @Output() form = this.directDebitDetailsStateService.form$;

  @Output() changedPaymentDetails =
    this.directDebitDetailsStateService.changedPaymentDetails$;

  @Output()
  changePaymentDetailsClicked =
    this.directDebitDetailsStateService.changePaymentDetails$;

  vm$ = this.directDebitDetailsStateService.vm$;

  constructor(
    private readonly directDebitDetailsStateService: SavedDirectDebitDetailsStateService
  ) {}

  changePaymentDetails() {
    this.directDebitDetailsStateService.changePaymentDetails();
  }

  cancelChangePaymentDetails() {
    this.directDebitDetailsStateService.cancelChangePaymentDetails();
  }

  setEditableForm(formGroup: FormGroup) {
    this.directDebitDetailsStateService.setEditableForm(formGroup);
  }
}

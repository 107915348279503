<div class="container glow-shadow__container">
  <ui-card
    *ngFor="let feature of features; let even = even; let first = first"
    variant="glow"
    cardContentJustify="center"
    [class.mt-3]="!first"
    [imgHorizontal]="true"
    [imgHorizontalPosition]="even ? 'right' : 'left'"
  >
    <ui-card-header>
      <ui-card-title>{{ feature.title }}</ui-card-title>
    </ui-card-header>
    <ui-card-body>
      {{ feature.description }}
    </ui-card-body>
    <ui-card-picture
      [style.min-width]="minWidth"
      [style.min-height]="minHeight"
      fit="cover"
      [fill]="true"
      [lazy]="true"
      [src]="feature.image"
    >
    </ui-card-picture>
  </ui-card>
  <ng-content></ng-content>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  applianceIsHeating,
  applianceIsWhiteGoods,
} from '@common/util-foundation';
import {
  CoverTypeName,
  PaymentTerm,
  PaymentType,
  Quote,
  QuoteSummaryConfig,
  WhichTitle,
} from '@common/util-models';

@Component({
  selector: 'wl-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss'],
})
export class QuoteSummaryComponent {
  @Input() set multiItemQuotes(quotes: Quote[]) {
    this.isMultiItem = true;
    this.buildQuoteSummary(...quotes);
  }
  @Input() set quote(quote: Quote) {
    this.isMultiItem = false;
    this.buildQuoteSummary(quote);
  }
  @Input() set selectedPaymentType(paymentType: PaymentType | undefined) {
    this.selectedPaymentTypeIsCard = paymentType === PaymentType.Card;
  }
  @Input() set config(config: QuoteSummaryConfig) {
    this.quoteSummaryConfig = config;
    this.buildQuoteSummaryTitle();
  }
  @Input() multipleExcessAvailable?: boolean;
  @Input() disabled?: boolean;

  @Output()
  multiItemDelete: EventEmitter<Quote & { last: boolean }> = new EventEmitter();

  applianceIsWhiteGoods?: boolean;
  isCardPaymentAvailable?: boolean;
  isDDPaymentAvailable?: boolean;
  isExpanded?: boolean;
  isFixedTerm?: boolean;
  isMultiItem?: boolean;
  quotes: Quote[] = [];
  quoteSummaryConfig?: QuoteSummaryConfig;
  selectedPaymentTypeIsCard?: boolean;
  title: string | undefined;

  trackQuote(index: number, quote: Quote): string {
    return quote.itemId;
  }

  handleMultiItemDelete(data: Quote & { last: boolean }): void {
    this.multiItemDelete.emit(data);
  }

  handleExpandedChange(expanded: boolean): void {
    this.isExpanded = expanded;
  }

  private buildQuoteSummary(...quotes: Quote[]): void {
    this.quotes = quotes;

    if (quotes.length === 1 && applianceIsHeating(quotes[0])) {
      this.isMultiItem = false;
    }

    this.isDDPaymentAvailable = quotes.some(
      (quote: Quote) => !!quote.cover?.paymentOptions?.directDebit
    );
    this.isCardPaymentAvailable = quotes.some(
      (quote: Quote) => !!quote.cover?.paymentOptions?.card
    );
    this.isFixedTerm = quotes.every(
      (quote: Quote) => quote.cover?.paymentTerm === PaymentTerm.FixedTerm
    );
    this.applianceIsWhiteGoods = quotes.some((quote: Quote) =>
      applianceIsWhiteGoods(quote)
    );
    this.buildQuoteSummaryTitle();
  }

  private buildQuoteSummaryTitle(): string | undefined {
    const quote: Quote = this.quotes[0];

    if (this.isMultiItem && !this.quoteSummaryConfig?.isForSmallScreen) {
      this.title = undefined;
      return;
    } else if (this.isMultiItem && this.quoteSummaryConfig?.isForSmallScreen) {
      this.title = `Quote (${this.quotes.length} ${
        this.quotes.length > 1 ? 'items' : 'item'
      })`;
      return;
    } else if (!quote) {
      this.title = undefined;
      return;
    }

    const coverType: string | undefined =
      quote.cover.name && quote.cover.name !== CoverTypeName.STD
        ? quote.cover.name?.trim() + ' protection'
        : undefined;
    const brand: string = quote.brandName?.trim() ?? '';
    const appliance: string = quote.applianceName?.trim() ?? '';

    switch (this.quoteSummaryConfig?.whichTitle) {
      case WhichTitle.selectedCoverName:
        this.title = coverType ?? `${brand} ${appliance}`;
        return;
      case WhichTitle.brandApplianceCover:
        this.title = `${brand} ${appliance} ${
          applianceIsHeating(quote) ? `<br>${coverType ?? ''}` : ''
        }`.trim();
        return;
      case WhichTitle.brandAppliance:
      default:
        this.title = `${brand} ${appliance}`;
        return;
    }
  }
}

<ng-container *ngIf="vm$ | async as vm">
  <div
    class="saved-payment-details payment-details dd-payment"
    *ngIf="vm.displayMode === 'ReadOnly'"
  >
    <div
      class="payment-details__title saved-payment-details__title heading justify--space-between align--center mt-2"
    >
      <h2>Your payment details<i class="icon icon--xl icon-lock"></i></h2>
      <i class="icon icon--lg icon-direct-debit-slim"></i>
    </div>
    <div class="payment-details__sub-title">
      <p>You can use these payment details or change them below.</p>
    </div>
    <div class="saved-payment-details__details mt-2">
      <div class="saved-payment-details__payment-info-section">
        <div class="saved-payment-details__payment-info form-group">
          <div class="saved-payment-details__label label">
            {{ vm.nameLabel }}
          </div>
          <div
            [dgxQmSanitise]="'block'"
            class="saved-payment-details__payment-detail"
          >
            {{ vm.savedPaymentDetails.accountName }}
          </div>
        </div>
        <div class="saved-payment-details__payment-info form-group">
          <div class="saved-payment-details__label label">
            {{ vm.accountNumberLabel }}
          </div>
          <div class="saved-payment-details__payment-detail">
            {{ vm.savedPaymentDetails.accountNumber }}
          </div>
        </div>
        <div class="saved-payment-details__payment-info form-group">
          <div class="saved-payment-details__label label">
            {{ vm.sortcodeLabel }}
          </div>
          <div class="saved-payment-details__payment-detail">
            {{ vm.savedPaymentDetails.sortCode }}
          </div>
        </div>
        <dgx-dfb-form
          [fieldset]="vm.readonlyFormFieldDef"
          [form]="vm.readonlyForm"
          [validate]="vm.validate"
        >
        </dgx-dfb-form>
      </div>
    </div>

    <div
      class="saved-payment-details__change-details mt-2"
      *ngIf="paymentDetailsEditionEnabled"
    >
      <button class="btn btn--secondary" (click)="changePaymentDetails()">
        Change payment details
      </button>
    </div>
  </div>

  <div
    class="change-payment-details payment-details dd-payment"
    *ngIf="vm.displayMode === 'Editable'"
    [id]="vm.newPaymentDetailsId"
  >
    <div
      class="payment-details__title change-payment-details__title heading justify--space-between align--center mt-2"
    >
      <h2>Your payment details<i class="icon icon--xl icon-lock"></i></h2>
      <i class="icon icon--lg icon-direct-debit-slim"></i>
    </div>
    <div class="payment-details__sub-title mb-2">
      <p>You can use this payment method or change it below.</p>
    </div>
    <div
      class="change-payment-details__invalid-details"
      *ngIf="vm.invalidPaymentDetailsEntered"
    >
      Your payment details are invalid. Please update and try again
    </div>
    <dgx-uib-direct-debit-form
      [cmsFormData]="vm.cmsFormData"
      [validate]="{ validate: vm.validate }"
      (form)="setEditableForm($event)"
    ></dgx-uib-direct-debit-form>

    <div
      class="change-payment-details__cancel-changes"
      *ngIf="vm.showCancelChangesButton"
    >
      <button
        class="btn btn--secondary mt-2 mb-2"
        (click)="cancelChangePaymentDetails()"
      >
        Cancel changes
      </button>
    </div>
  </div>
</ng-container>
